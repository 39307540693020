<template>
  <div>
    <Banner bg-image="green-bg-2.jpg" talk-img="find-someone.png">
      <template v-slot:title> Find someone to talk to</template>
      <template v-slot:subtitle>
        Help is available. Reach out to any of the numbers below whenever you
        need support. There is someone waiting to talk to you now.
      </template>
      <template v-slot:description> </template>
    </Banner>

    <section class="my-8 call-sect md:my-12 md:py-8">
      <div class="max-w-6xl px-4 mx-auto sm:px-6">
        <div class="mb-6 text-3xl font-bold call-text text-blue">
          Call, text or chat online with someone who can support you, whenever
          you need it.
        </div>
        <p class="mb-6">
          These numbers are confidential and can help talk through your feelings
          to help keep you safe.
        </p>

        <div class="mb-6 text-2xl font-bold text-blue">Call</div>

        <div class="grid grid-cols-1 gap-6 md:grid-cols-3">
          <div class="call">
            <div class="font-bold call-ttl text-blue">
              Hope for Wellness Help Line
              <div class="text-base font-normal text-blue">(across Canada)</div>
            </div>

            <a href="tel:+1-855-242-3310" class="flex align-center text-blue">
              <img src="../assets/images/phone-icon.svg" class="mr-2" />
              <span> 1-855-242-3310</span>
            </a>
          </div>

          <div class="call">
            <div class="font-bold call-ttl text-blue">
              Indian Residential Schools Crisis Line
              <div class="text-base font-normal text-blue">(across Canada)</div>
            </div>

            <a href="tel:+18669254419" class="flex align-center text-blue">
              <img src="../assets/images/phone-icon.svg" class="mr-2" />

              <span>1-866-925-4419</span>
            </a>
          </div>

          <div class="call">
            <div class="font-bold call-ttl text-blue">
              Distress Center
              <div class="text-base font-normal text-blue">(Calgary)</div>
            </div>

            <a href="tel:4032664357" class="flex align-center text-blue">
              <img src="../assets/images/phone-icon.svg" class="mr-2" />
              <span>403-266-HELP (24/7)</span>
            </a>
          </div>

          <div class="call">
            <div class="font-bold call-ttl text-blue">
              Canada Suicide Prevention Service
              <div class="text-base font-normal text-blue">(across Canada)</div>
            </div>
            <a href="tel:18334564566" class="flex align-center text-blue">
              <img src="../assets/images/phone-icon.svg" class="mr-2" />
              <span>1-833-456-4566 (24/7)</span>
            </a>
          </div>

          <div class="call">
            <div class="font-bold call-ttl text-blue">
              Connecteen
              <div class="text-base font-normal text-blue">(Calgary)</div>
            </div>
            <a href="tel:4032648336" class="flex align-center text-blue">
              <img src="../assets/images/phone-icon.svg" class="mr-2" />
              <span
                >Call (24/7):<br />
                403-264-8336</span
              >
            </a>
          </div>

          <div class="call">
            <div class="font-bold call-ttl text-blue">
              Kamatsiaqtut Help Line
              <div class="text-base font-normal text-blue">(Nunavut)</div>
            </div>
            <a href="tel:18002653333" class="flex align-center text-blue">
              <img src="../assets/images/phone-icon.svg" class="mr-2" />
              <span
                >Toll Free<br />
                1-800-265-3333</span
              >
            </a>
            <a href="tel:18679793333" class="flex align-center text-blue">
              <img src="../assets/images/phone-icon.svg" class="mr-2" />
              <span
                >In Iqaluit<br />
                1-867-979-3333</span
              >
            </a>
          </div>
        </div>

        <div class="my-6 text-2xl font-bold text-blue">Text</div>

        <div class="grid grid-cols-1 gap-6 md:grid-cols-1">
          <div class="call">
            <div class="h-auto font-bold call-ttl text-blue">
              Connecteen
              <div class="text-base font-normal text-blue">(Calgary)</div>
            </div>
            <a href="tel:5873332724" class="flex align-center text-blue">
              <img src="../assets/images/text-icon.svg" class="mr-2" />
              <span>Text (daily): 587-333-2724</span>
            </a>
          </div>
        </div>

        <div class="my-6 text-2xl font-bold text-blue">Chat online</div>

        <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div class="call">
            <div class="h-auto font-bold call-ttl text-blue">
              Hope for Wellness Help Line
            </div>
            <a href="http://www.hopeforwellness.ca" class="text-green">
              <div class="text-blue">Online chat:</div>
              www.hopeforwellness.ca
            </a>
          </div>
          <div class="call">
            <div class="h-auto font-bold call-ttl text-blue">
              Confidential Chat
            </div>
            <a
              href="https://calgaryconnecteen.com/"
              class="flex align-center text-green"
            >
              https://calgaryconnecteen.com/
            </a>
          </div>
        </div>
      </div>
    </section>

    <!-- Contact -->
    <section>
      <div class="max-w-5xl px-4 mx-auto reach-out sm:px-6">
        <div class="text-center bg-transparent border-0">
          <div class="mb-6 text-2xl font-bold text-blue">
            Reach out to the Miskanawah Healing Lodge for support
          </div>

          <div
            class="justify-center mt-8 text-center md:flex align-center md:mt-0"
          >
            <GreenBtn text="Contact us" name="GetInTouchWithUs"></GreenBtn>
          </div>
        </div>
      </div>
    </section>

    <!-- Quote -->
    <Quote>
      <template v-slot:text
        >I was getting bullied at school and then I started wishing I was dead.
        I spoke to someone at the distress centre, and they helped me.</template
      >
      <template v-slot:sign> MP </template>
    </Quote>
  </div>
</template>

<script>
import Quote from "@/components/Quote";
import GreenBtn from "@/components/GreenBtn";
import Banner from "@/components/Banner";
export default {
  name: "FindSomeoneToTalkTo",
  components: {
    Quote,
    GreenBtn,
    Banner,
  },
};
</script>

<style scoped>
/* FIND SOMEONE
--------------------- */

.call {
  background: #f8f8f8;
  font-size: 18px;
  padding: 30px;
}
.call-ttl {
  font-size: 22px;
  margin-bottom: 10px;
  line-height: normal;
  min-height: 60px;
}

/* REACH OUT */
.reach-out {
  background-image: url("../assets/images/support-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 80px;
}

@media (min-width: 767px) {
  .find-text {
    display: block;
  }
  .reach-out {
    background-size: contain;
    padding: 80px 180px;
  }
}
</style>
